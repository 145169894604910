<template>
  <div class="d-flex d-col vh100">
    <TopToolbar />
    <div class="d-flex grow ">
      <Sidebar />
      <Rooms class="grow page_container" />
    </div>
    <Footer />
  </div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Rooms from "@/components/Rooms/Rooms.vue";
import Sidebar from "@/components/Toolbars/Sidebar";
import Footer from "@/components/Toolbars/Footer.vue";

export default {
  name: "Register",
  components: {
    Rooms,
    TopToolbar,
    Sidebar,
    Footer,
  },
};
</script>

