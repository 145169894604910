<template>
  <div>
    <section>
      <div class="d-flex align-center pt-15">
        <button
          @click="back"
          class="pointer d-flex center"
          :title="tip && $t('rooms.tip.close')"
        >
          <s-icon color="green">close</s-icon>
          <div class="green--text">{{ $t("rooms.close") }}</div>
        </button>
      </div>

      <hr class="my-15" />
      <div class="d-flex sm-col">
        <div class="details_grid">
          <div>{{ $t("rooms.account_owner") }}</div>
          <div class="opacity-60 weight-300">
            {{ firstName }} {{ lastName }}
          </div>
          <div>{{ $t("rooms.total_rooms") }}</div>
          <div class="opacity-60 weight-300">{{ rooms.length }}</div>
          <div>{{ $t("rooms.admin_rooms") }}</div>
          <div class="opacity-60 weight-300">{{ rooms.length }}</div>
        </div>
        <div class="details_grid">
          <div class="missingText"></div>
          <div class="missingText"></div>
          <div>{{ $t("rooms.scheduled_meetings") }}</div>
          <div class="opacity-60 weight-300">{{ schedule.length }}</div>
          <div>{{ $t("rooms.total_attendees") }}</div>
          <div class="opacity-60 weight-300">{{ attendees.length }}</div>
        </div>
      </div>
      <hr class="mt-15" />
      <section class="d-flex py-15 space-between align-center">
        <div class="d-flex searchBar" :title="tip && $t('rooms.tip.search')">
          <s-text-field
            v-model="search"
            autofocus
            ref="search"
            :placeholder="$t('attendees.search')"
            block
          ></s-text-field>
          <div class="box center pointer" @click="focus()">
            <s-icon color="grey" class="">account-search-outline</s-icon>
          </div>
        </div>
        <div v-if="role == 'manager'">
          <s-btn
            @click.native="newRoom"
            class="green addButton ml-10"
            :title="tip && $t('rooms.tip.add')"
          >
            <s-icon width="21" class="pr-10" color="white">door-open</s-icon>
            <div class="grow">
              <div>{{ $t("rooms.add_room") }}</div>
            </div>
          </s-btn>
        </div>
      </section>

      <hr />
      <div class="grid uppercase">
        <div
          v-for="item in headings"
          :key="item.idx"
          class="align-center d-col py-15 lightBg"
        >
          <s-icon height="21" color="grey">{{ item.icon }}</s-icon>
          <div class="opacity-60 font-12 pt-10 hide_on_mobile">
            {{ item.title }}
          </div>
        </div>
      </div>
      <hr />
    </section>

    <section class="pt-10 table">
      <div v-for="item in filtedItems" :key="item.id" class="grid">
        <div class="item">
          <div
            class="colorBox relative pointer"
            @click="openPicker(item)"
            :style="{ backgroundColor: 'var(--sm-color-' + item.color + ')' }"
          >
            <s-icon class="rotate">menu-right</s-icon>
            <s-color-picker
              v-click-outside="closePicker"
              v-model="item.color"
              @input_Event="updateColor"
              v-if="item.id == pickerID"
            ></s-color-picker>
          </div>
          <s-text-field
            block
            @keyup.native.enter="update(item)"
            @blur="update(item)"
            v-model="item.title"
          />
        </div>
        <div class="item">
          <div class="admin darkBg w100 center" block disabled>
            {{ $t("rooms.administrator") }}
          </div>
        </div>

        <div class="d-flex">
          <div class="item grow mr-1">
            <s-text-field block disabled v-model="item.manager.name" />
          </div>
          <div class="item grow ml-1">
            <s-text-field
              @blur="update(item)"
              @keyup.native.enter="update(item)"
              block
              disabled
              v-model="item.manager.email"
            />
          </div>
        </div>
        <div class="d-flex">
          <div
            @click="edit(item)"
            :title="tip && $t('rooms.tip.edit')"
            class="item grow mr-1 darkBg center pointer hover"
          >
            <s-icon color="grey">square-edit-outline</s-icon>
          </div>
          <div
            @click="del(item)"
            :title="tip && $t('rooms.tip.delete')"
            class="item grow ml-1 darkBg center pointer hover"
          >
            <s-icon color="grey">trash-can-outline</s-icon>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      items: [],
      filtedItems: [],
      search: "",
      admin: "Administrator",
      pickerID: -1,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    openPicker(val) {
      this.pickerID = val.id;
      this.$store.commit("rooms/editRoom", val);
    },
    closePicker() {
      this.pickerID = -1;
    },
    async updateColor(val) {
      this.$store.commit("rooms/editColor", val);
      await this.$store.dispatch("rooms/updateRoom");
      this.closePicker();
    },
    update(item) {
      let ob = this.rooms.find((element) => element.id == item.id);
      let ob2 = this.rooms.find((el) => this.searchRooms(el, item));
      if (ob2) return this.notification(ob, item);
      if (JSON.stringify(ob) === JSON.stringify(item)) return;
      this.$store.commit("rooms/editRoom", item);
      this.$store.commit("modals/updateRoomsConfirmation", true);
    },
    del(item) {
      this.$store.commit("rooms/editRoom", item);
      this.$store.commit("modals/deleteRoomConfirmationModal", true);
    },
    edit(val) {
      this.$store.commit("rooms/editRoom", val);
      this.$store.commit("modals/roomModal", true);
    },
    searchRooms(el, item) {
      return el.title == item.title && el.id !== item.id;
    },
    notification(ob, item) {
      item.title = ob.title;
      return this.$store.commit("notifications/push", {
        error: this.$t("server.room_already_exists"),
      });
    },
    async newRoom() {
      let valid = await this.$store.dispatch("user/isNewRoomValid");
      if (!valid) return this.$store.commit("modals/billingModal", true);
      this.$store.commit("rooms/clearRoom");
      this.$store.commit("modals/roomModal", true);
    },
    filterSearch() {
      this.filtedItems = this.items.filter((val) => {
        let term = this.search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        let title = val.title.toLowerCase();
        let name = val.manager.name.toLowerCase();
        let email = val.manager.email.toLowerCase();
        title = title.search(term.toLowerCase()) !== -1;
        name = name.search(term.toLowerCase()) !== -1;
        email = email.search(term.toLowerCase()) !== -1;
        return title || name || email;
      });
    },
    focus() {
      this.$refs.search.onFocus();
    },
  },
  computed: {
    rooms() {
      let rooms = this.$store.state.rooms.rooms;
      rooms = rooms.filter((el) => !el.isDeleted);
      return JSON.parse(JSON.stringify(rooms));
    },
    editRooms() {
      return this.$store.state.rooms.edit;
    },
    headings() {
      return [
        { icon: "door-open", title: this.$t("rooms.room_name") },
        { icon: "account-star", title: this.$t("rooms.managed_by") },
        { icon: "account-star", title: this.$t("rooms.adminEmail") },
        {
          icon: "cursor-default-click-outline",
          title: this.$t("rooms.actions"),
        },
      ];
    },
    ...mapState("attendees", ["attendees"]),
    ...mapState("schedule", ["schedule"]),
    ...mapState("user", ["firstName", "lastName", "tip", "role"]),
  },
  watch: {
    rooms(val) {
      let items = JSON.parse(JSON.stringify(val));
      this.items = items;
      this.filterSearch();
    },
    search(val) {
      let items = JSON.parse(JSON.stringify(this.rooms));
      this.items = items;
      this.filterSearch();
    },
  },
  mounted() {
    this.filtedItems = this.rooms;
    this.$store.dispatch("rooms/getRoomsInformation");
  },
};
</script>

<style lang="less" scoped>
.table {
  max-height: calc(100vh - 411px);
  overflow-y: auto;
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 110px;
  grid-column-gap: 2px;
}

.details_grid {
  display: grid;
  grid-template-columns: 160px 160px;
  max-height: 200px;
  grid-row-gap: 5px;
}

.item {
  color: rgb(180, 180, 180);
  border-bottom: 1px solid #42515e;
  min-height: 36px;
  font-size: 16px;
  display: flex;
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.admin {
  font-size: 12px;
  color: #53bdc1;
}

.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.colorBox {
  min-width: 20px;
  width: 20px;
  background-color: #151b23;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.searchBar {
  max-width: 480px;
  width: 100%;
}

.rotate {
  transform: translate(4px, 8px) rotate(45deg);
  opacity: 0;
}

.rotate:hover {
  opacity: 1;
}

.addButton {
  min-width: min-content;
  margin-left: 15px;
}

.darkBg {
  background-color: rgba(0, 0, 0, 0.35);
}

.lightBg {
  background-color: rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 80px;
  }

  .table {
    max-height: calc(100vh - 414px);
  }
}
</style>